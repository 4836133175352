// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}


.rotate-container {
    width: 200px;
    height: 200px;
  animation: myAnim 4s linear infinite;
}

img {
    max-width: 100%;
    height: auto;
}

@keyframes myAnim {
	0%,
	50%,
	100% {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;IACI,YAAY;IACZ,aAAa;EAEf,oCAAoC;AACtC;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;CACC;;;EAGC,UAAU;CACX;;CAEA;;EAEC,UAAU;CACX;AACD","sourcesContent":[".loader-body {\n    margin: 0;\n    padding: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 80vh;\n}\n\n\n.rotate-container {\n    width: 200px;\n    height: 200px;\n    -webkit-animation: myAnim 4s linear infinite;\n  animation: myAnim 4s linear infinite;\n}\n\nimg {\n    max-width: 100%;\n    height: auto;\n}\n\n@keyframes myAnim {\n\t0%,\n\t50%,\n\t100% {\n\t\topacity: 1;\n\t}\n\n\t25%,\n\t75% {\n\t\topacity: 0;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
