import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TickCircle, CloseCircle } from "iconsax-react";
import { logOutUser } from "../../redux/actions/authActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";

interface MyModalProps {
  isOpen: boolean;
  closeModal: () => void;


}

export default function MyModal({ isOpen, closeModal, }: MyModalProps) {
  const dispatch = useDispatch()
  const { isAuthenticated, user, error } = useSelector(
    (state: any) => state.auth
  );
  const [logOut, setLogOut] = useState<boolean>(false)
  const handleClick =() => {
    setLogOut(true)
  }

  if (!isAuthenticated){
    toast.success(user)
  }
  useEffect(()=> {
    if(logOut){
      dispatch(logOutUser() as unknown as AnyAction);
    }

  }, [logOut])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[350px] flex flex-col items-left max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h4"
                    className="text-lg font-bold leading-6 text-gray-900 "
                  >
                    <div className=" flex flex-row-reverse ">
                        <CloseCircle onClick={closeModal} className="cursor-pointer"/>
                    </div>
                  Sign out
                  </Dialog.Title>
                  <p className=" text-sm my-4">
                  Are you sure you want to sign out?
                  </p>
               
               <div className="flex flex-col items-center">
               <button onClick={handleClick} type="button" className="items-center bg-purple-900 py-2 px-7 rounded-lg text-white text-sm w-[50%]">
                    Sign Out
                </button>
               </div>
             
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

