import DataTable, { TableStyles } from "react-data-table-component";
import { IPending, customStyles } from "../../utils/models";
import { Fragment, useCallback, useEffect, useState } from "react";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import TxnDropDown from "../../components/dropdowns/TxnDropDown";
import { ThreeDots } from "react-loader-spinner";
import TxnAuthModal from "../../components/modals/TxnAuthModal";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { ArrowDown2, Filter } from "iconsax-react";

const PendingApproval = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPending[]>([]);
  //   let [openDisableModal, setOpenDisableModal] = useState(false);
  let [batchId, setBatchId] = useState("");
  let [action, setAction] = useState("");
  let [transType, setTransType] = useState<string>("All")
  let [approval, setApproval] = useState(false);
  let [openModal, setOpenModal] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  const fetchData = async () => {
    setIsLoading(true);

    const config = {
      method: "get",
      url: `/api/v1/master/pending-approval`,
    };

    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data?.statusCode == 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        setIsLoading(false);
        toast.success(response.data.message);
        let data = response.data.data
        if(transType == "Minimum"){
          const minData = data.filter((item : IPending) => item.uploadType == "MINIMUM")
          setData(minData);
        }
        else if (transType == "Trans"){
          const transData = data.filter((item : IPending) => item.uploadType == "TRANS")
          setData(transData);
        }
        else{
          setData(data);
        }
       
      })
      .catch((error) => {
        console.log(error);

        toast.error(error.response.data.message);
        setIsLoading(false);
      });
  };

  const batch = (data: string) => {
    setBatchId(data);
  };

  const modalStates = (data: boolean) => {
    setOpenModal(data);
  };

  const actionType = (data: string) => {
    setAction(data);
  };
  const isApproved = (data: boolean) => {
    setApproval(data);
  };
  const columns = [
    {
      name: "File Name",
      selector: (row: { fileName: string }) => row.fileName,
    },
    {
      name: "Batch Id",
      selector: (row: { batchId: string }) => row.batchId,
    },
    {
      name: "Maker",
      selector: (row: { uploadedBy: string }) => row.uploadedBy,
    },
    {
      name: "Type",
      selector: (row: { uploadType: string }) => row.uploadType,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row: { status: string }) => row.status,
    },
    {
      name: "File Seq. No.",
      selector: (row: { fileSequenceNo: string }) => row.fileSequenceNo,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row: { uploadDate: string }) => row.uploadDate,
    },
    {
      name: "Txn Count",
      selector: (row: { transactionCount: number }) => row.transactionCount,
      width: "100px",
    },
    {
      name: "",

      cell: (row: IPending) => (
        <div>
          {
            <TxnDropDown
              data={row.batchId}
              batch={batch}
              modalStates={modalStates}
              actionType={actionType}
              isApproved={isApproved}
            />
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [transType]);

  function closeModal() {
    setIsOpen(false);
    setOpenModal(false);
  }

  return (
    <>
      <TxnAuthModal
        openDisableModal={openModal}
        closeModal={closeModal}
        data={batchId}
        approve={approval}
        action={action}
      />

      <section className=" bg-white rounded-2xl space-y-11 pb-10">
        <div className="flex flex-row justify-between m-10">
          <div className="flex justify-between w-full">
            <div>
              <p className="text-2xl text-zinc-800 font-semibold">
                Pending Transactions
              </p>
            </div>
            <div className="  m-2">
              <Menu as="div" className="relative inline-block text-left">
                <div className="  bg-slate-100 pt-2 pr-2 rounded-sm ring-1 ring-purple-900">
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1 ">
                  <Filter  className="inline ml-4" size="22" color="#5C2684" variant="Bold"/>
                  <span className=" text-sm">{transType}</span>
                  <ArrowDown2 className="inline ml-1" size="18" color="#5C2684"/>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className=" bg-white absolute right-0 z-10 mt-2 w-40 origin-top-right shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-light">
                    <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                      <div
                      onClick={() => setTransType("All")} 
                    className={classNames(
                      active ? " bg-purple-300 text-zinc-900" : "text-zinc-900",
                      "block px-3 py-3 text-sm"
                    )}
                  >
                    {"All"}
                  </div>
                  )}
                      </Menu.Item>
                      <Menu.Item>
                      {({ active }) => (
                      <div
                      onClick={() => setTransType("Trans")} 
                    className={classNames(
                      active ? " bg-purple-300 text-zinc-900" : "text-zinc-900",
                      "block px-3 py-3 text-sm"
                    )}
                  >
                    {"Trans"}
                  </div>
                  )}
                      </Menu.Item>
                      <Menu.Item>
                      {({ active }) => (
                      <div
                      onClick={() => setTransType("Minimum")} 
                    className={classNames(
                      active ? " bg-purple-300 text-zinc-900" : "text-zinc-900",
                      "block px-3 py-3 text-sm"
                    )}
                  >
                    {"Minimum"}
                  </div>
                  )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            
          </div>
        </div>
        <Toaster />
        <div className="m-12">
          {isLoading ? (
            <div className="flex justify-center">
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#5C2684"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <DataTable
              className="react-data-table-component"
              columns={columns}
              progressPending={isLoading}
              data={data}
              customStyles={customStyles as TableStyles}
              pagination
            />
          )}
        </div>
      </section>
    </>
  );
};

export default PendingApproval;
