import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TickCircle, CloseCircle } from "iconsax-react";
import { logOutUser } from "../../redux/actions/authActions";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

interface MyModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export default function MyModal({ isOpen, closeModal }: MyModalProps) {
  const dispatch = useDispatch();
  const [logOut, setLogOut] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [usernameError, setUsernameError] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [tokenSent, setTokenSent] = useState<boolean>(false)

  const resetSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tokenSent){
      if (!username || username === "") {
        setUsernameError("Username is required");
        setTokenError("");
      }  else {
        setIsLoading(true);
        const config = {
          method: "get",
          url: `/api/v1/user/generate-reset-token?username=${username}`,
          contentType: "application/json-patch+json",
        };
  
        axiosInterceptorInstance(config)
          .then((response) => {
            if (response?.data.statusCode !== 200) {
              toast.error(response?.data.message);
              setIsLoading(false); 
              
            } 
          
              else {
              toast.success(response?.data.message);
              setIsLoading(false);
              setTokenSent(true)
            }
          })
          .catch((error) => {
            
              toast.error(error.response?.data.message || error.response?.data.Message || error.message);
              setIsLoading(false);
          });
      }
    }
    else if(tokenSent){
    
        if (!token || token === "") {
          setUsernameError("");
          setTokenError("Token is required");
        }  else {
          setIsLoading(true);
          const config = {
            method: "post",
            url: `/api/v1/user/reset-user`,
            data : {
              username,
              token
            },
            contentType: "application/json-patch+json",
          };
    
          axiosInterceptorInstance(config)
            .then((response) => {
              if (response.data?.statusCode == 401){
                localStorage.clear();
                window.location.href = "/login";
              }
              if (response?.data.statusCode !== 200) {
             
                toast.error(response?.data.message);
                setIsLoading(false);
                setTokenSent(true)
                setTokenSent(false)
              } else {
          
                toast.success(response?.data.message);
                setIsLoading(false);
                setTokenSent(false)
                setTimeout(()=> 
                  window.location.reload(),
                3000
                )
                
              }
            })
            .catch((error) => {
              toast.error(error.response?.data.message || error.response?.data.Message);
              setIsLoading(false);
              setTokenSent(false);
            });
        }
    }
 
  };

  return (
    <>
      <Toaster />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[400px] flex flex-col items-center max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h4"
                    className="text-lg font-bold leading-6 text-gray-900 "
                  >
                    {/* <div className=" flex flex-row-reverse ">
                        <CloseCircle onClick={closeModal} className="cursor-pointer"/>
                    </div> */}
                    Reset User
                  </Dialog.Title>
                  <form
                    onSubmit={resetSubmit}
                    className="flex flex-col space-y-6 mt-10 "
                  >
                    {
                      !tokenSent && 
                      <div className="w-[340px] ">
                      <label className="block">
                        <span className=" block text-base font-light text-slate-700">
                          Username / Email
                        </span>
                        <input
                          type="text"
                          className="placeholder:text-slate-400 block w-full bg-neutral-50 h-10 border border-neutral-50 rounded-md py-2 pr-16 pl-3 shadow-sm focus:outline-none focus:border-purple-600 focus:bg-white  sm:text-sm"
                          placeholder="John Doe"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </label>
                      <p className="py-1 font-light text-red-700 text-[13px]">
                        {usernameError}
                      </p>
                    </div>
                    }
               
                    {tokenSent && <div className="w-[340px] ">
                      <label className="block">
                        <span className=" block text-base font-light text-slate-700">
                          Token
                        </span>
                        <input
                          type="text"
                          className="placeholder:text-slate-400 block w-full bg-neutral-50 h-10 border border-neutral-50 rounded-md py-2 pr-16 pl-3 shadow-sm focus:outline-none focus:border-purple-600 focus:bg-white  sm:text-sm"
                          placeholder="******"
                          value={token}
                          onChange={(e) => setToken(e.target.value)}
                        />
                      </label>
                      <p className="py-1 font-light text-red-700 text-[13px]">
                        {tokenError}
                      </p>
                    </div> 
                    }
                    {isLoading ? (
                      <button
                        disabled
                        className="self-center rounded-md w-fit bg-purple-300 text-white text-[12px] font-semibold px-10 py-2"
                      >
                        <ThreeDots
                          visible={true}
                          height="20"
                          width="20"
                          color="#fff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="self-center rounded-md w-fit bg-purple-800 text-white text-[12px] font-semibold px-10 py-2"
                      >
                        {
                          !tokenSent ? "Generate Token" : "Submit"
                        }
                
                      </button>
                    )}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
