import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    CLEAR_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ALL_REQUEST,
    LOGOUT_ALL_SUCCESS,
    LOGOUT_ALL_FAIL,
    INITIAL_STATE
} from '../constants/authConstants'


interface AuthState {
    user: string | null;
    isAuthenticated: boolean;
    loading: boolean;
    error?: string | null;
    message : string | null
}

const initialState: AuthState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    message : null
};
const defaultState = initialState
export const authReducer = (state = defaultState, action : any) : AuthState => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case LOAD_USER_REQUEST:
        case LOGOUT_REQUEST:
        case LOGOUT_ALL_REQUEST:
            return {
                ...state,
                loading : true,
            }
        case LOGIN_SUCCESS:
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                loading : false,
                isAuthenticated : true,
                user : action.payload,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                message : action.payload,
                loading : false,
            }

        case LOGOUT_ALL_SUCCESS:
            return{
                ...state,
                message : action.payload,
                loading : false,
                isAuthenticated : false,
                user : null
            }
        
        case LOGOUT_ALL_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                loading : false,
                error : action.payload
            }
        case LOAD_USER_FAIL:
            return {
                ...state,
                loading : false,
                error : action.payload
            }
        
        case CLEAR_ERROR:
            return {
                ...state,
                error : null
            }
        case INITIAL_STATE:
                return initialState
        default:
            return state
    }
}

