import { Calendar } from "iconsax-react";
import { useState } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";

interface IDateProps {
  dateSelected: Date | null;
  handleDateChange: (date: Date) => void;
  placeholder: string;
  label : string
}

const DatePicker: React.FC<IDateProps> = ({
  dateSelected,
  handleDateChange,
  placeholder,
  label
}) => {

  return (
    <div className=" flex flex-col space-y-5">
      <label className=" text-zinc-800">
        {label}
      </label>
      <ReactDatePicker
        // showIcon
        selected={dateSelected}
        onChange={handleDateChange}
        placeholderText={placeholder}
        icon={<Calendar size="50" color="red" className="my-1"/>}
        className="placeholder:text-slate-400 block w-[500px] te bg-neutral-50 h-12 border border-neutral-50 rounded-md py-2 pr-16 pl-3 shadow-sm
         focus:border-purple-600 focus:bg-white
        focus:outline-none"
        wrapperClassName="myCustomDate-1"

      />
    </div>
  );
};

export default DatePicker;
