import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-body">
      <div className="rotate-container">
        <img src="/assets/fcmblogo.png" alt="logo" />
      </div>
    </div>
  );
};

export default Loader;
