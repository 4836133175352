import {Route, RouterProvider, createBrowserRouter, createRoutesFromElements} from "react-router-dom"

import { Suspense } from "react";
import Dashboard from "../pages/dashboard";
import DashboardLayout from "../layouts/dashboardLayout";
import Profile from "../pages/profile";
import Uploads from "../pages/uploads";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../pages/auth";
import Report from "../pages/report";
import PendingApproval from "../pages/pendingApproval";
import Transaction from "../pages/transaction";


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
        <Route path="/login">
            <Route index element={<Login />} />
        </Route>

        <Route path="/" element={< DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="report" element={<Report />} />
          <Route path="pending" element={<PendingApproval />} />
          <Route path=":type" element={<Uploads />} />
          <Route path="transaction/:batch" element={<Transaction />} />
        </Route>     
        </>
    )
)


const AppRouter = () => {
    return (
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    );
  };
  
  export default AppRouter;