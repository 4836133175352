import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ArrowSquareDown } from "iconsax-react";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const TxnDropDown = (props : any) => {
  const declineModal = () => {
    props.batch(props.data);
    props.modalStates(true);
    props.actionType("Decline");
    props.isApproved(false)
  };

  const approveModal = () => {
    props.batch(props.data);
    props.modalStates(true);
    props.actionType("Approve");
    props.isApproved(true)
  };

  const navigate = useNavigate()
  const viewModal = () => {
  navigate(`/transaction/${props.data}`)
  };

  const items = [
    {
      name: "View",
      onClickEvent: viewModal,
    },
    {
      name: "Approve",
      onClickEvent: approveModal,
    },
    {
      name: "Decline",
      onClickEvent: declineModal,
    }
  ];

  function classNames(...classes : any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Menu as="div" className="relative inline-block text-left menu-modal">
      <div>
        <Menu.Button className="inline-flex w-full border border-purple-900 justify-center items-center text-purple-900 rounded-md bg-white  px-4 py-1 text-sm font-medium   ">
          Actions
          <ArrowSquareDown
            className="inline  ml-4"
            size="14"
            color="#5C2684"
            variant="Bold"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0  mt-1 w-40 origin-top-right z-50 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-light">
          <div className="py-1">
            {items.map((item, i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? " bg-purple-900 text-white" : "text-zinc-700",
                      "block py-2 px-2 w-full text-sm text-start text-white "
                    )}
                    onClick={item.onClickEvent}
                  >
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default TxnDropDown;
