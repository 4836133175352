import { ReactNode } from "react"

interface cadProps  {
    children : ReactNode,
    className : string
}

const Card = ({ children, className} : cadProps ) => {
    return (
      <div className={className + " " + "flex flex-col bg-white rounded-lg border border-neutral-900 border-opacity-20 justify-start items-start my-3 p-3"}>{children}</div>
    )
  }
  
  export default Card