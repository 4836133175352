// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define the custom header style */
.custom-datatable .p-datatable .p-datatable-thead .p-column-title {
    background-color: purple !important;
    color: black;
    font-weight: bold;

  }
  
  /* Adjust the alignment of the paginator */
  .custom-datatable .p-paginator .p-paginator-left,
  .custom-datatable .p-paginator .p-paginator-right {
    padding: 5px !important;
  }

  .p-datatable-header .p-datatable .p-datatable-thead > tr > th {
    background-color: red ;
  }

  .p-datatable-thead th {
    background: purple !important;
  }

  .ui-paginator-pages .ui-paginator-page{
    background: none;
    background-color:#cccccc;
    }
    .ui-paginator-pages .ui-state-active{
    background-color: red !important;
    }
    .ui-paginator-pages .ui-state-hover{
    background-color: red !important;
    }`, "",{"version":3,"sources":["webpack://./src/pages/uploads/Uploads.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,mCAAmC;IACnC,YAAY;IACZ,iBAAiB;;EAEnB;;EAEA,0CAA0C;EAC1C;;IAEE,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,gBAAgB;IAChB,wBAAwB;IACxB;IACA;IACA,gCAAgC;IAChC;IACA;IACA,gCAAgC;IAChC","sourcesContent":["/* Define the custom header style */\n.custom-datatable .p-datatable .p-datatable-thead .p-column-title {\n    background-color: purple !important;\n    color: black;\n    font-weight: bold;\n\n  }\n  \n  /* Adjust the alignment of the paginator */\n  .custom-datatable .p-paginator .p-paginator-left,\n  .custom-datatable .p-paginator .p-paginator-right {\n    padding: 5px !important;\n  }\n\n  .p-datatable-header .p-datatable .p-datatable-thead > tr > th {\n    background-color: red ;\n  }\n\n  .p-datatable-thead th {\n    background: purple !important;\n  }\n\n  .ui-paginator-pages .ui-paginator-page{\n    background: none;\n    background-color:#cccccc;\n    }\n    .ui-paginator-pages .ui-state-active{\n    background-color: red !important;\n    }\n    .ui-paginator-pages .ui-state-hover{\n    background-color: red !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
