import  { Fragment, useState } from "react";
import DatePicker from "../../components/datepicker";
import { IReport, customStyles } from "../../utils/models";
import DataTable, { TableStyles } from "react-data-table-component";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { convertApproved, convertDate } from "../../utils/dataManipulation";
import UploadDropdown from "../../components/dropdowns/UploadDropdown";
import ViewTxnModal from "../../components/modals/ViewTxnModal";
import { DocumentDownload } from "iconsax-react";

const Report = () => {

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date |null>(null);
  const [data , setData] = useState<IReport[]>([])
  const [openTxnModal, setOpenTxnModal] = useState(false)
  const [batchId, setBatchId] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const openTxn = (data : boolean)=> {
    setOpenTxnModal(data)
  }
  const batch = (data : string)=> {
    setBatchId(data)
  }

  const modalData = data?.filter((item : IReport) => item.batchId === batchId )
  const closeTxnModal = ()=> {
    setOpenTxnModal(false)
  }
  const handleReport = () => {
    setIsLoading(true)
    const config = {
      method: "get",
      url: `/api/v1/report/uploads?StartDate=${convertDate(startDate)}&EndDate=${convertDate(endDate)}`,
    };
    axiosInterceptorInstance(config)
       
      .then((response) => {
        if (response.data?.statusCode == 401){
          localStorage.clear();
          window.location.href = "/login";
        }
        setIsLoading(false);
       
        setData(convertApproved(response.data.data));
        toast.success(response.data.message);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.message );
        setIsLoading(false);
      });
  }

  const handleStartDate = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDate = (date: Date) => {
    setEndDate(date);
  };

  const columns = [
    {
      name: "S/N",
      selector: (row: { id: number }) => row.id,
      width: "60px",
    },
    {
      name: "File Name",
      selector: (row: { fileName: string }) => row.fileName,
    },
    {
      name: "Batch Id",
      selector: (row: { batchId: string }) => row.batchId,
    },
    {
      name: "Amount",
      selector: (row: { processedAmount: number }) => row.processedAmount,
    },
    {
      name: "Type",
      selector: (row: { uploadType: string }) => row.uploadType,
    },


    {
      name: "Status",
      selector: (row: { status: string }) => row.status,
    },

    {
      name: "Upload Date",
      selector: (row: { uploadDate: string }) => row.uploadDate,
    },
    {
      name: "",

      cell: (row: IReport) => (
        <div>
          {
            <UploadDropdown
              data={row.batchId}
              openTxn={openTxn}
              batch={batch} 
            />
          }
        </div>
      ),
    },

  ];
function convertArrayOfObjectsToCSV(array : any) {
	let result : any;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(data[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach((item : any) => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

function downloadCSV(array :any) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}


  return (
    
    <Fragment>
        <Toaster />
      <section className=" bg-white rounded-2xl space-y-11 pb-10">
        <div className="flex flex-row justify-between m-10">
          <div>
            <p className="text-2xl text-zinc-800 font-semibold">Report</p>
          </div>
          <div>
            <DocumentDownload 
             size="25"
             color="#592581"
             className="cursor-pointer"
             onClick={() => downloadCSV(data)}
             />
            
          </div>
        </div>
        <div className="flex flex-row justify-between m-10">
          <DatePicker
            dateSelected={startDate}
            handleDateChange={handleStartDate}
            placeholder="Start Date"
            label="Start Date"
          />
          <DatePicker
            dateSelected={endDate}
            handleDateChange={handleEndDate}
            placeholder="End Date"
            label="End Date"
          />
        </div>
        <div className="flex flex-row-reverse">
        <button
              onClick={handleReport}
              className="self-end rounded-md bg-purple-800 text-white text-[12px] font-semibold px-8 py-2 mx-10"
            >
              Search
            </button>
        </div>
    
        <div className="m-10">
        {data.length !== 0 && (
          <DataTable
          className="react-data-table-component"
            columns={columns}
            data={data}
            pagination
            persistTableHead
            customStyles={customStyles as TableStyles}
          />
          )}


          <ViewTxnModal isOpen={openTxnModal} closeModal={closeTxnModal} info={modalData} />
        </div>
      </section>
    </Fragment>
  );
};

export default Report;
