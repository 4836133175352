import CryptoJS from "crypto-js";
const encKey = process.env.REACT_APP_ENCRYPTION_KEY as string;

export const Encryption = (number: string) => {
  var key = CryptoJS.enc.Utf8.parse(encKey);
  var iv = CryptoJS.enc.Utf8.parse(encKey);
  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(number), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};