import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TickCircle, CloseCircle } from "iconsax-react";
import { IReport } from "../../utils/models";
import DataTable, { TableStyles } from "react-data-table-component";

interface MyModalProps {
  isOpen: boolean;
  closeModal: () => void;
  info: IReport[];
}

export default function MyModal({ isOpen, closeModal, info }: MyModalProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[1500px] flex flex-col items-left max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h4"
                    className="text-lg font-bold leading-6 text-gray-900 "
                  >
                    <div className=" flex flex-row-reverse mb-4">
                      <CloseCircle
                        onClick={closeModal}
                        className="cursor-pointer"
                      />
                    </div>
                  </Dialog.Title>
                  <table className="text-zinc-700 text-sm">
                    <tbody>
                      <tr className=" bg-purple-900 text-white">
                        <th>S/N</th>
                        <th>Data</th>
                        <th>Value</th>
                      </tr>
                      <tr>
                        <td>1.</td>
                        <td>Approved Date</td>
                        <td>{info[0]?.approvedDate}</td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>Approved By</td>
                        <td>{info[0]?.approvedBy}</td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>TXN Count </td>
                        <td>{info[0]?.transactionCount}</td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>Batch Id </td>
                        <td>{info[0]?.batchId}</td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>Status </td>
                        <td>{info[0]?.status}</td>
                      </tr>
                      <tr>
                        <td>6.</td>
                        <td>Upload Date </td>
                        <td>{info[0]?.uploadDate}</td>
                      </tr>
                      <tr>
                        <td>7.</td>
                        <td>Upload Type </td>
                        <td>{info[0]?.uploadType}</td>
                      </tr>
                      <tr>
                        <td>8.</td>
                        <td>File Seq. No.</td>
                        <td>{info[0]?.fileSequenceNo}</td>
                      </tr>
                      <tr>
                        <td>9.</td>
                        <td>File Total Amount </td>
                        <td>{info[0]?.fileTotalAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
