import { useEffect, useRef, useState } from "react"
import {useIdleTimer} from "react-idle-timer"
import IdleModal from "../modals/IdleModal"
import { useDispatch, useSelector } from "react-redux"
import { logOutUser } from "../../redux/actions/authActions"
import { AnyAction } from "redux"


const IdleTimeOut = () => {
    const dispatch = useDispatch()
    const timeout = parseInt(process.env.REACT_APP_TIMEOUT as string) * 60 * 1000
    const [remaining, setRemaining] = useState(timeout)
    const [elapsed, setElapsed] = useState(0)
    const [lastActive, setLastActive] = useState<Date | null>(new Date())
    const [isIdle, setIsIdle] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const {isAuthenticated} = useSelector((state : any)=> state.auth)
    const [counter, setCounter] = useState<number>(5)
    const [secondCounter, setSecondCounter] = useState<any>()
    const closeModal = () => {
      setOpenModal(false)
    }
  
    const handleOnActive = () => setIsIdle(false)
    const accessToken = localStorage.getItem("accessToken")
    const handleOnIdle = () =>{
      if(accessToken){
        setOpenModal(true)
      }     
    }

    const { getRemainingTime,
      getLastActiveTime,
      getElapsedTime } = useIdleTimer({
        timeout: timeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500,
      });
      
   
      useEffect(() => {
        setRemaining(getRemainingTime())
        setLastActive(getLastActiveTime())
        setElapsed(getElapsedTime())

        if(openModal){
          counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
        }
        else{
          setCounter(5)
        }
        if (counter === 0 && accessToken){
          dispatch(logOutUser() as unknown as AnyAction)
          return () => {
            setOpenModal(false);
         };
         }
        
      }, [openModal, counter])

  return (
    <div>
       <IdleModal isOpen={openModal} closeModal={closeModal} data={counter}/>
    </div>
  )
}

export default IdleTimeOut