import {Dispatch } from 'redux';
import axiosInstance from '../../utils/axiosInstance'
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    CLEAR_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_ALL_REQUEST,
    LOGOUT_ALL_SUCCESS,
    LOGOUT_ALL_FAIL,
    INITIAL_STATE
} from '../constants/authConstants'
import jwtDecode from 'jwt-decode';



export const loginUser = (username : string, password : string, token : string) => async (dispatch : Dispatch)  => {
    try {
        dispatch({ type : LOGIN_REQUEST});
        const config = {
            method : 'post',
            url : `/api/v1/user/login`,
            data : {
                username,
                password,
                token
            }
        }
    
        axiosInstance(config)
        .then(({data}) => (
            dispatch({
                type : LOGIN_SUCCESS,
                payload : data.data
            })
        ))
        .catch(function (error : any) {
            if (error.response) {
              dispatch({
                type : LOGIN_FAIL,
                payload : error.response.data
            })
             
            } else if (error.request) {
                    dispatch({
                      type : LOGIN_FAIL,
                      payload : error.request.data
                  })
             
            
            } else {
                dispatch({
                    type : LOGIN_FAIL,
                    payload : error.message
                })
             
            }
          });
        
       
    } catch (error) {
        dispatch({

            type : LOGIN_FAIL,
            payload : error
        })
    }

}

export const loadUser = () => (dispatch : Dispatch) => {
    try {
        dispatch({ type : LOAD_USER_REQUEST});
        let accessToken = localStorage.getItem("accessToken") || ""
        let user : string
        user = jwtDecode(accessToken)
    

        dispatch({
            type : LOAD_USER_SUCCESS,
            payload : user
        })
    } catch (error) {
        dispatch({
            type : LOAD_USER_FAIL,
            payload : "Unauthorized"
        })
    }
}

export const clearErrors = () => (dispatch : Dispatch) => {
    dispatch({
        type : CLEAR_ERROR
    })
}

export const initial = () => (dispatch : Dispatch) => {
    dispatch({
        type : INITIAL_STATE
    })
}

export const logOutUser = () => (dispatch : Dispatch) => {
    try {
      
        dispatch({ type : LOGOUT_REQUEST});
        const config = {
            method : 'post',
            url : `/api/v1/user/logout`,
        }    
        axiosInstance(config)
        .then((response) => {
            localStorage.clear()
            dispatch({
                type : LOGOUT_SUCCESS,
                payload : response.data.message
            })
        })
              
    } catch (error) {
        dispatch({
            type : LOGOUT_FAIL,
            payload : false
        })
    }
}

export const logOutAll = (username : string) => (dispatch : Dispatch) => {
    try {
      
        dispatch({ type : LOGOUT_ALL_REQUEST});
        const config = {
            method : 'post',
            url : `/api/v1/user/logout-all?username=${username}`,
        }    
        axiosInstance(config)
        .then((response) => {
            dispatch({
                type : LOGOUT_ALL_SUCCESS,
                payload : response.data.message
            })
        })
      
    } catch (error : any) {
        dispatch({
            type : LOGOUT_ALL_FAIL,
            payload : error.data.data.message
        })
    }
}

