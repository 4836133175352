import { ReactNode } from "react";

interface EllipseProps {
  className?: string;
  children: ReactNode;
}

const Ellipse = ({className, children}:EllipseProps ) => {
    return (
  <div className={className + " grid place-content-center rounded-full"}>
      {children}
      </div>
    )
    
  };
  
  export default Ellipse;
  