import "./Navbar.css";
import Dropdown from "../dropdowns/Dropdown";
import { useSelector } from "react-redux";
import { getFullName, getFirstLetter } from "../../utils/dataManipulation";
import { uploadsList } from "../../utils/uploadsData";
import { Link, NavLink } from "react-router-dom";
import SignOutDropdown from "../dropdowns/SignOutDropdown";
import { useState } from "react";
import SignOutModel from "../../components/modals/SignOutModal";

const Navbar = () => {
  const { user } = useSelector((state: any) => state.auth);
  const [openSignOut, setOpenSignOut] = useState<boolean>(false);
  const closeSignOut = () => {
    setOpenSignOut(false);
  };

  const openSO = (data: boolean) => {
    setOpenSignOut(data);
  };

  return (
    <div>
      <header className="bg-white border-b border-neutral-900 border-opacity-20">
        <section className="max-w-7xl mx-auto  flex justify-between">
          <div className="flex justify-center space-x-10 items-center">
            <div className=" py-2">
              <img
                src="/assets/fcmblogo2.png"
                alt="fcmb_logo"
                width={55}
                height={50}
              />
            </div>

            {user?.role == "MAKER" && (
              <div className=" space-x-10 text-zinc-800 text-base font-semibold leading-normal .nav-content">
                <NavLink
                  to="/"
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Dashboard
                </NavLink>
                <NavLink to={""}>
                 
                  <Dropdown display="Branch" items={uploadsList} />{" "}
                </NavLink>
                <NavLink
                  to={"/report"}
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Report
                </NavLink>
              </div>
            )}

            {user?.role == "CHECKER" && (
              <div className=" space-x-10 text-zinc-800 text-base font-semibold leading-normal .nav-content">
                <NavLink
                  to="/"
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Dashboard
                </NavLink>
          
                <NavLink
                  to={"/pending"}
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Authorization
                </NavLink>
                <NavLink
                  to={"/report"}
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Report
                </NavLink>
              </div>
            )}

            {(user?.role == "ADMIN" || user?.role == "SUPERUSER" ) && (
              <div className=" space-x-10 text-zinc-800 text-base font-semibold leading-normal .nav-content">
                <NavLink
                  to="/"
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  Dashboard
                </NavLink>

                <NavLink
                  to="/profile"
                  className=" pb-5 "
                  style={({ isActive }) =>
                    isActive
                      ? {
                          borderBottom: "3px solid #592581",
                        }
                      : {}
                  }
                >
                  User Management
                </NavLink>
              </div>
            )}
   
          </div>

          <div className="flex flex-row space-x-4 items-center">
            <div className="bg-purple-800 rounded-full h-12 w-12 p-2 grid place-content-center">
              <h1 className=" text-lg font-semibold text-white">
                {getFirstLetter(user?.staff)}
              </h1>
            </div>

            <div className=" space-x-10 text-zinc-800 text-base font-light leading-normal">
              <NavLink to={""} className="flex flex-row">
                <div className="mt-1">{getFullName(user?.staff)}</div>

                <div className="mt-1">
                  <SignOutDropdown display="" openSO={openSO} />
                </div>

                {openSignOut && (
                  <SignOutModel
                    isOpen={openSignOut}
                    closeModal={closeSignOut}
                  />
                )}
              </NavLink>
            </div>
          </div>
        </section>
      </header>
    </div>
  );
};

export default Navbar;
