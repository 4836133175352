import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { TickCircle, CloseCircle } from "iconsax-react";
import { initial, logOutAll, logOutUser } from "../../redux/actions/authActions";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

interface MyModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data: number
}

export default function MyModal({ isOpen, closeModal, data }: MyModalProps) {
  const dispatch = useDispatch()
  const [logOut, setLogOut] = useState<boolean>(false)
  const {loading, message, isAuthenticated, user} = useSelector((state : any)=> state.auth)
  const handleClick =() => {
    dispatch(logOutUser() as unknown as AnyAction);
  }


  useEffect(()=> {
    if(message){  
     setTimeout(()=> {
      window.location.reload()
     },1000)
     toast.success(message)
    }
  
    dispatch(initial() as unknown as AnyAction)
    closeModal()

  }, [message])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200" 
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[400px] space-y-6 flex flex-col items-left max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h4"
                    className="text-lg font-bold leading-6 text-gray-900 "
                  >
                    <div className=" flex flex-row-reverse ">
                        <CloseCircle onClick={closeModal} className="cursor-pointer"/>
                    </div>
                    Idle
                  </Dialog.Title>
                  <p className=" text-sm my-4">
                  You have been Idle for a while, you'll be logged out soon
                  
                  </p>
               
               <div className="flex flex-row items-center space-x-4">
                  {loading ? (
                  <button
                    className=" justify-center items-center bg-purple-200 py-2 px-7 rounded-lg text-white text-sm w-[50%]"
                    disabled
                  >
                    <ThreeDots
                      visible={true}
                      height="30"
                      width="30"
                      color="#fff"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </button>
                ) : (
                  <button onClick={handleClick} type="button" className="items-center bg-purple-900 py-2 px-7 rounded-lg text-white text-sm w-[50%]">
                  Log Out
              </button>
                )}
            
                <button onClick={closeModal} type="button" className="items-center border border-purple-900 py-2 px-7 rounded-lg text-purple-900 text-sm w-[50%]">
                   cancel
                </button>
               </div>
             
                </Dialog.Panel>
              </Transition.Child>
            </div>3
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

