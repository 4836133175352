import './App.css';
import AppRouter from './routes/AppRouter';
import IdleTimeOut from './components/idleTimer';

function App() {
  return (
    <div>
      <IdleTimeOut />
      <AppRouter />
    </div>
  );
}

export default App;
