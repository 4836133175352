export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL= "LOGIN_FAIL"
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL= "LOAD_USER_FAIL"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL= "LOGOUT_FAIL"
export const LOGOUT_ALL_REQUEST= "LOGOUT_ALL_REQUEST"
export const LOGOUT_ALL_SUCCESS= "LOGOUT_ALL_SUCCESS"
export const LOGOUT_ALL_FAIL= "LOGOUT_ALL_FAIL"

export const CLEAR_ERROR = "CLEAR_ERRORS"
export const INITIAL_STATE = "INITIAL_STATES"