import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import { loadUser } from "../redux/actions/authActions";
import { useEffect } from "react";
import store from "../redux/store";
import { useSelector } from "react-redux";


const DashboardLayout = () => {
  const navigate = useNavigate();
  const {isAuthenticated} = useSelector((state : any)=> state.auth)
  const auth = localStorage.getItem("accessToken")
  useEffect(() => {
    if(!auth){
      navigate("/login")
    }
    else {
      store.dispatch(loadUser());
    }
   
  }, [isAuthenticated]);

  return (
    <section className="min-h-screen bg-neutral-100 ">
      <Navbar />
      <section className="flex flex-col mt-12 max-w-7xl mx-auto space-y-10 justify-center">
        <Outlet />
      </section>
    </section>
  );
};

export default DashboardLayout;