import { IModifiedUser, IReport, IUser } from "./models"

export const getFirstLetter = (username : string) => {
    if(username){
    return String(username.trim()).charAt(0)

    }
}

export const getFullName = (username : string) => {
    if(username){

    return String(username).replace(".", " ")
    }
}

export const getFirstNAme = (username : any) => {
    username = String(username).split(".")
    username = username[0]
    return username
}
export const timeManipulation= (time : any) => {
    time = String(time).split("T")
    time = time[0]
    return time
}

export const getLastName = (username : any) => {
    username = String(username).split("@")
    username = username[0].split(".")
    username = username[1]
    return username
}

export const convertUser = (users: any[]): IModifiedUser[] => {
    const userData =  users.map((user: any, index: number) => ({
        id: index + 1,
        firstName: getFirstNAme(user.username),
        lastName: getLastName(user.username),
        email: user.username,
        role: user.role,
        status: user.isActive,
        dateCreated: timeManipulation(user.createdOn),
        createdBy: user.createdBy,
        approvalFlag : user.approvalFlag
    }));
    return userData;
};

export const convertApproved = (data: any[]): IReport[] => {
    const userData =  data.map((datum: any, index: number) => ({
        id: index + 1,
        fileName: datum.fileName,
        transactionCount: datum.transactionCount,
        processedAmount: datum.processedAmount,
        batchId: datum.batchId,
        uploadType: datum.uploadType,
        fileSequenceNo: datum.fileSequenceNo,
        fileTotalAmount: datum.fileTotalAmount,
        uploadDate: datum.uploadDate,
        uploadedBy: datum.uploadedBy,
        approvedBy: datum.authorizedBy,
        approvedDate: datum.authorizedDate,
        status: datum.status,
      
    }));
    return userData;
};


export const convertDate =(dateString : string | number | Date | null) : string | null => {
if(!dateString){
    return null
}
const date = new Date(dateString);
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0');
const day = String(date.getDate()).padStart(2, '0');

const formattedDate = `${year}/${month}/${day}`;
return formattedDate
}