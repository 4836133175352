import { defaultThemes } from 'react-data-table-component';

export interface IUploads {
  accountNumber: string
  amount: number
  batchId: string
  clientNo: string
  fileSequenceNo: string
  fileTotalAmount: number
  marker: string
  narration: string
  partType: string
  processStatus: string
  processedAmount: number
  processedDate: string
  processedFile: string
  processor: string
  slipNo: string
  transactionCount: number
  uploadedFile: string
  valueDate: string
  valueDateString: string
}

export interface IMinUploads {
  masterCardAccount: string
  closingBalance: number
  dueAmount: number
  batchId: string
  fileSequenceNo: string
  uploadId: string
  processStatus: string
  processor: string
  closingDate: string
  processId: string
  transactionCount: number
  processedFile: string
  recordDate: string
  processedDate: string
  closingDateString: string
}

export interface IReport {
  id: number
  fileName: string
  transactionCount: number
  processedAmount: number
  batchId: string
  uploadType: string
  fileSequenceNo: string
  fileTotalAmount: number
  status: string
  uploadDate: string
  uploadedBy: string
  approvedBy: string
  approvedDate: string
}

export interface IPending {
  fileName: string
  transactionCount: number
  uploadType: string
  status: string
  uploadedBy: string
  batchId : string
  uploadDate : string
  fileSequenceNo : string
}

export interface IUser {
  approvalFlag: string
  approvedBy: string
  createdBy: string
  createdOn: string
  id: number
  isAccountLocked: boolean
  isActive: boolean
  isLoggedIn: boolean
  isTokenValid: boolean
  lastLogin: string
  lastModified: string
  loginFailureCount:number
  role: string
  username:  string
}

export interface IModifiedUser {
  firstName: string
  lastName: string
  email: string
  dateCreated: string
  createdBy: string
  id: number
  role: string
  status:  boolean
  approvalFlag : string
}

export type TPendingUsers = {
  username : string
  role : string
  createdby : string
  CreatedOn : string
}

export const customStyles = {

  tableWrapper: {
    style: {
      marginBottom : "1px",
      padding: "0px",
      background: "red",
      overflow: "visible",
    
    }
  },
  header: {
    style: {
      minHeight: '10px',
      overflow: "visible",
    },
  },
  headCells: {
    style: {
      color: "white",
      background: "#6B21A8"

    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};




