import React, { Fragment, useEffect, useState } from 'react'
import DataTable, { TableStyles } from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { IUploads, customStyles } from '../../utils/models';
import axiosInterceptorInstance from '../../utils/axiosInstance';
import { Back } from 'iconsax-react';

const Transaction = () => {
    let { batch } = useParams<string>();
    let [isLoading, setIsLoading] = useState<boolean>()
    let [data, setData] = useState<IUploads[]>([])

    const fetchData =()=> {
        setIsLoading(true)
        const config = {
          method: "get",
          url: `/api/v1/master/uploads/${batch}`,
        };
        axiosInterceptorInstance(config)
        .then((response) => {
          if (response.data?.statusCode == 401){
            localStorage.clear();
            window.location.href = "/login";
          }
          setIsLoading(false);
         
          setData(response.data.data);
          toast.success(response.data.message);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error)
          toast.error(error.message );
          setIsLoading(false);
        });
    }
    const columns= [
        {
          name: "Account Number",
          selector: (row: { accountNumber: string }) => row.accountNumber,
        },
        {
          name: "Amount",
          selector: (row: { amount: number }) => row.amount,
        },
        {
          name: "Batch Id",
          selector: (row: { batchId: any }) => row.batchId,
        },
        {
          name: "Part Type",
          selector: (row: { partType: any }) => row.partType,
        },
        {
          name: "Client Number",
          selector: (row: { clientNo: any }) => row.clientNo,
        },
        // {
        //   name: "Marker",
        //   selector: (row: { marker: any }) => row.marker,
        // },
        // {
        //   name: "Narration",
        //   selector: (row: { narration: any }) => row.narration,
        // },
        {
          name: "Slip Number",
          selector: (row: { slipNo: any }) => row.slipNo,
        },
        {
          name: "Txn Count",
          selector: (row: { transactionCount: any }) => row.transactionCount,
        },
        {
          name: "Value Date",
          selector: (row: { valueDate: any }) => row.valueDate,
        },
        {
          name: "Value Date",
          selector: (row: { valueDate: any }) => row.valueDate,
        },
    
        
      ];

      useEffect(()=> {
        fetchData()
      }, [])
  return (
    <Fragment>
        <Toaster />
      <section className=" bg-white rounded-2xl space-y-11 pb-10">
        <div className="flex flex-row justify-between m-10">
          <div>
            <p className="text-2xl text-zinc-800 font-semibold">Report</p>
          </div>
          <div className=' flex flex-row'>
          <Back size="25" color="#592581" variant="Bold"/>
          Back
          </div>
        </div>

      

        <div className="m-12">
        {data.length !== 0 && (
                <DataTable
                columns={columns}
                data={data}
                customStyles={customStyles as TableStyles}
                pagination
              />
          )}
        </div>
      </section>
    </Fragment>
  )
}

export default Transaction