import { DocumentUpload } from "iconsax-react";
import { useDropzone } from "react-dropzone";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import { useState, useCallback } from "react";
import UploadModal from "../../components/modals/UploadModal";
import { uploadsData } from "../../utils/uploadsData";
import { useParams } from "react-router-dom";
import { IMinUploads, IUploads } from "../../utils/models";
import toast, { Toaster } from "react-hot-toast";
import "./Uploads.css";
import DataTable, { TableStyles } from "react-data-table-component";
import { customStyles } from "../../utils/models";
import { ThreeDots } from "react-loader-spinner";
import UnderConst from "../../assets/img/under_construction.png";

const Uploads = () => {
  const columnMin = [
    {
      name: "Mastercard Acct.",
      selector: (row: { masterCardAccount: any }) => row.masterCardAccount,
    },
    {
      name: "Due Amount",
      selector: (row: { dueAmount: number }) => row.dueAmount,
    },
    {
      name: "Batch Id",
      selector: (row: { batchId: any }) => row.batchId,
    },
    {
      name: "Closing Bal.",
      selector: (row: { closingBalance: any }) => row.closingBalance,
    },
    {
      name: "Process Id",
      selector: (row: { processId: any }) => row.processId,
    },
    {
      name: "File Seq No.",
      selector: (row: { fileSequenceNo: any }) => row.fileSequenceNo,
    },
    {
      name: "Processed Date",
      selector: (row: { processedDate: any }) => row.processedDate,
    },
    {
      name: "Record Date",
      selector: (row: { recordDate: any }) => row.recordDate,
    },
    {
      name: "Closing Date",
      selector: (row: { closingDateString: any }) => row.closingDateString,
    },
  ];

  const columns = [
    {
      name: "Account Number",
      selector: (row: { accountNumber: string }) => row.accountNumber,
    },
    {
      name: "Amount",
      selector: (row: { amount: number }) => row.amount,
      width: "120px",
    },
    {
      name: "Processed Amount",
      selector: (row: { processedAmount: number }) => row.processedAmount,
      width: "120px",
    },
    {
      name: "Batch Id",
      selector: (row: { batchId: any }) => row.batchId,
    },
    {
      name: "Type",
      selector: (row: { partType: any }) => row.partType,
      width: "70px",
    },
    {
      name: "Client Number",
      selector: (row: { clientNo: any }) => row.clientNo,
    },
    {
      name: "Slip Number",
      selector: (row: { slipNo: any }) => row.slipNo,
    },
    {
      name: "File Seq. No.",
      selector: (row: { fileSequenceNo: any }) => row.fileSequenceNo,
    },
    {
      name: "Value Date",
      selector: (row: { valueDate: any }) => row.valueDate,
    },
  ];

  type uploadType =
    | "minimum_due"
    | "trans_file"
    | "credit_payment"
    | "resend_file";
  const [isLoading, setIsLoading] = useState(false);
  const [copyItem, setCopyItem] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadData, setUploadData] = useState<IUploads[]>([]);
  const [uploadMinData, setUploadMinData] = useState<IMinUploads[]>([]);
  const [selectedRow, setSelectedRows] = useState<IUploads[]>([]);
  const [selectedRowMin, setSelectedRowsMin] = useState<IMinUploads[]>([]);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  let { type } = useParams<string>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAcceptedFiles(acceptedFiles);
  }, []);

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handleRowSelectedMin = useCallback((state: any) => {
    setSelectedRowsMin(state.selectedRows);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  const pageType = type as unknown as uploadType;
  let pageTypeData = uploadsData[pageType];

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  });
  const files = acceptedFiles.map((file: any) => (
    <li className="list-none inline" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const upLoadDocument = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error("Select a file");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    const config = {
      method: "post",
      url: `/api/v1/master/type?type=${pageTypeData.type}`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    };

    axiosInterceptorInstance(config)
      .then((response) => {
        setIsLoading(false);
        if (response.data?.statusCode === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        if (pageTypeData.type === "TRANS") {
          setUploadData(response.data.data);
        } else {
          setUploadMinData(response.data.data);
        }
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.Message || "Upload failed");
        setIsLoading(false);
      });
  };

  const handleInitiate = async () => {
    let initiate: string[];
    let urlQuery: string;
    if (pageTypeData?.type === "TRANS") {
      initiate = selectedRow.map((item: IUploads) => item.slipNo);
      urlQuery = uploadData[0].batchId;
    } else {
      initiate = selectedRowMin.map(
        (item: IMinUploads) => item.masterCardAccount
      );
      urlQuery = uploadMinData[0].batchId;
    }
    setIsLoading(true);

    const config = {
      method: "post",
      url: `/api/v1/master/initiate?batchId=${urlQuery}`,
      data: {
        exemptedAccounts: initiate,
      },
    };

    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data.statusCode === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        setIsLoading(false);
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.Message);
        setIsLoading(false);
      });
  };
  if (pageType === "credit_payment" || pageType === "resend_file") {
    return (
      <>
        <div className=" flex justify-center items-center h-full bg-white rounded-lg">
          <img src={UnderConst} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <UploadModal
        isOpen={isOpen}
        copyItem={copyItem}
        closeModal={closeModal}
      />
      <div className="text-2xl text-zinc-800 font-bold">
        {pageTypeData?.title}
      </div>
      <Toaster />
      <form onSubmit={upLoadDocument}>
        {/* <div className="mt-2 bg-violet-100 min-h-[200px] flex justify-center bg-opacity-50 rounded-lg border border-dashed border-gray-900/25 px-8 py-12 items-center"> */}
        <div className="text-center">
          {/* <div
              {...getRootProps({
                className: "dropzone m-12 flex text-sm leading-6 text-gray-600",
              })}
            >
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer text-zinc-500 text-sm font-medium"
              >
                <span>
                  <DocumentUpload
                    className="inline"
                    size="22"
                    color="#5C2684"
                    variant="Bold"
                  />
                  {files.length !== 0 ? (
                    files
                  ) : (
                    <span>
                      Drag your file or{" "}
                      <span className="text-indigo-500">Click here</span> to include file
                    </span>
                  )}
                </span>
                <input
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  {...getInputProps()}
                />
              </label>
            </div> */}
          <div
            {...getRootProps({
              className:
                "mt-2 bg-violet-100 min-h-[200px] flex justify-center bg-opacity-50 rounded-lg border border-dashed border-gray-900/25 px-8 py-12 items-center",
            })}
          >
            <div className="text-center">
              <input {...getInputProps()} />

              {files.length > 0 ? (
                files
              ) : (
                <span>
                  <DocumentUpload
                    className="inline"
                    size="22"
                    color="#5C2684"
                    variant="Bold"
                  />
                  Drag your file or{" "}
                  <span className="text-indigo-500">Click here</span> to include
                  file
                </span>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="flex flex-row-reverse mt-12">
          {isLoading ? (
            <button
              className="rounded-md bg-purple-300 text-white text-[12px] font-semibold px-10 py-2"
              disabled
            >
              <ThreeDots
                visible={true}
                height="30"
                width="30"
                color="#fff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </button>
          ) : (
            <button className="rounded-md bg-purple-800 text-white text-[12px] font-semibold px-10 py-2">
              Upload
            </button>
          )}
        </div>
      </form>

      <div className="table-view mb-14">
        {uploadData.length !== 0 && (
          <>
            <DataTable
              columns={columns}
              progressPending={isLoading}
              data={uploadData}
              customStyles={customStyles as TableStyles}
              pagination
              selectableRows
              onSelectedRowsChange={handleRowSelected}
            />

            <div className="flex flex-row-reverse mt-12">
              {isLoading ? (
                <button
                  className="rounded-md bg-purple-300 text-white text-[12px] font-semibold px-10 py-2"
                  disabled
                >
                  <ThreeDots
                    visible={true}
                    height="30"
                    width="30"
                    color="#fff"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </button>
              ) : (
                <button
                  onClick={handleInitiate}
                  className="rounded-md bg-purple-800 text-white text-[12px] font-semibold px-10 py-2"
                >
                  Initiate
                </button>
              )}
            </div>
          </>
        )}

        {uploadMinData.length !== 0 && (
          <>
            <DataTable
              columns={columnMin}
              data={uploadMinData}
              customStyles={customStyles as TableStyles}
              pagination
              selectableRows
              onSelectedRowsChange={handleRowSelectedMin}
            />

            <div className="flex flex-row-reverse mt-12">
              {isLoading ? (
                <button
                  className="rounded-md bg-purple-300 text-white text-[12px] font-semibold px-10 py-2"
                  disabled
                >
                  <ThreeDots
                    visible={true}
                    height="30"
                    width="30"
                    color="#fff"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </button>
              ) : (
                <button
                  onClick={handleInitiate}
                  className="rounded-md bg-purple-800 text-white text-[12px] font-semibold px-10 py-2"
                >
                  Initiate
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Uploads;
