import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ArrowSquareDown } from "iconsax-react";
import { useSelector } from "react-redux";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import toast from "react-hot-toast";

const PendAction = (props: any) => {
  const decline = () => {
    const config = {
      method: "post",
      url: "/api/v1/user/approval-user",
      contentType: "application/json-patch+json",
      data: {
        username: props.username,
        isApproved: false,
      },
    };

    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data?.statusCode == 401){
          localStorage.clear();
          window.location.href = "/login";
        }
        toast.success(response.data.message);
        window.location.reload();
   
      })
      .catch((error) => {
        toast.error(error.data.message);
        window.location.reload();
        
      });
  };

  const approve = () => {
    const config = {
      method: "post",
      url: "/api/v1/user/approval-user",
      contentType: "application/json-patch+json",
      data: {
        username: props.username,
        isApproved: true,
      },
    };

    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data?.statusCode == 401){
          localStorage.clear();
          window.location.href = "/login";
        }
        toast.success(response.data.message);
        window.location.reload();
  
      })
      .catch((error) => {
        toast.error(error.data.message);
        window.location.reload();
    
      });
  };

  let items = [
    {
      name: "Approve",
      onClickEvent: approve,
    },
    {
      name: "Decline",
      onClickEvent: decline,
    },
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Menu as="div" className="relative inline-block text-left menu-modal">
      <div>
        <Menu.Button className="inline-flex w-full border border-purple-900 justify-center items-center text-purple-900 rounded-md bg-white  px-4 py-1 text-sm font-medium   ">
          Actions
          <ArrowSquareDown
            className="inline  ml-4"
            size="14"
            color="#5C2684"
            variant="Bold"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0  mt-1 w-40 origin-top-right z-50 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-light">
          <div className="py-1">
            {items.map((item, i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? " bg-purple-900 text-white" : "text-zinc-700",
                      "block py-2 px-2 w-full text-sm text-start text-white "
                    )}
                    onClick={item.onClickEvent}
                  >
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PendAction;
