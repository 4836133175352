import { SearchNormal1, ArrowDown2 } from "iconsax-react";
import Select, { components } from "react-select";
import { useEffect, useRef, useState, useMemo, ChangeEvent } from "react";
import ActionDropdown from "../../components/dropdowns/ActionDropdown";
import axiosInterceptorInstance from "../../utils/axiosInstance";
import { getAllUsers } from "../../redux/actions/usersActions";
import {
  convertUser,
  getFirstNAme,
  getLastName,
  timeManipulation,
} from "../../utils/dataManipulation";
import { useDispatch, useSelector } from "react-redux";
import MyModal from "../../components/modals/UserAddedModal";
import DisableUserModal from "../../components/modals/DisableUserModal";
import { AnyAction } from "redux";
import DataTable, { TableStyles } from "react-data-table-component";
import styled from "styled-components";
import { IModifiedUser, IUser, TPendingUsers } from "../../utils/models";
import { customStyles } from "../../utils/models";
import { ThreeDots } from "react-loader-spinner";
import { Toaster } from "react-hot-toast";
import PendAction from "../../components/dropdowns/PendAction";

const Profile = () => {
  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  const { loading, users } = useSelector((state: any) => state.users);

  const FilterComponent = ({ filterText, onFilter }: any) => (
    <>
      <div>
        <label className="relative block ">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 right-0 flex items-center p-2">
            <SearchNormal1 className="m-4" size="24" color="#292D32" />
          </span>
          <input
            className=" placeholder:text-slate-400 block w-[400px]  bg-neutral-50 h-10 border border-neutral-50 rounded-md mb-6 py-2 pr-16 pl-3 shadow-sm focus:outline-none focus:border-purple-600 focus:bg-white  sm:text-sm"
            placeholder="johndoe@fcmb.com"
            type="text"
            value={filterText}
            onChange={onFilter}
            autoFocus
          />
        </label>
      </div>
      {/* <TextField
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        autoFocus
      /> */}
    </>
  );
  const columns = [
    {
      name: "S/N",
      selector: (row: { id: number }) => row.id,
      width: "60px",
    },
    {
      name: "First Name",
      selector: (row: { firstName: string }) => row.firstName,
      width: "110px",
    },
    {
      name: "Last Name",
      selector: (row: { lastName: any }) => row.lastName,
      width: "110px",
    },
    {
      name: "Email",
      selector: (row: { email: any }) => row.email,
    },
    {
      name: "Role",
      selector: (row: { role: any }) => row.role,
      width: "90px",
    },
    {
      name: "Status",
      width: "120px",
      cell: (row: { status: boolean }) => (
        <>
          {row.status ? (
            <div className="font-semibold bg-emerald-500 bg-opacity-20 rounded-full text-emerald-500 text-center text-[0.7rem] px-4 py-1">
              Active
            </div>
          ) : (
            <div className="font-semibold bg-red-500 bg-opacity-20 rounded-full text-center text-red-500 text-[0.7rem] px-4 py-1">
              Inactive
            </div>
          )}
        </>
      ),
    },
    {
      name: "Created By",
      selector: (row: { createdBy: any }) => row.createdBy,
    },
    {
      name: "Date Created",
      selector: (row: { dateCreated: any }) => row.dateCreated,
      width: "110px",
    },
    {
      name: "",

      cell: (row: IModifiedUser) => (
        <div>
          {
            <ActionDropdown
              data={row.email}
              createdBy={row.createdBy}
              userData={userData}
              modalStates={modalStates}
              actionType={actionType}
            />
          }
        </div>
      ),
    },
  ];

    const pendingColumn = [
    {
      name: "S/N",
      cell: (row: any, index: number) => index + 1,
      width: "60px",
    },
    {
      name: "Username",
      selector: (row: { username: string }) => row.username,
     
    },
    {
      name: "Role",
      selector: (row: { role: string }) => row.role,
     
    },
    {
      name: "Created By",
      selector: (row: { createdby: string }) => row.createdby,
    },
    {
      name: "Date Created",
      selector: (row: { CreatedOn: string }) => row.CreatedOn.split("T")[0],
     
    },
    {
      name: "",

      cell: (row: TPendingUsers) => (
        <div>
          {
            <PendAction
              username={row.username}
            />
          }
        </div>
      ),
    },
  ];
  const usernames = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<{ value: string }>();
  const [username, setUsername] = useState("");
  const [modalData, setModalData] = useState({});
  const [disableModalData, setDisableModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  let [openDisableModal, setOpenDisableModal] = useState(false);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState("");
  const [pendingUser, setPendingUser] = useState<TPendingUsers[]>([])
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const userProfileData = convertUser(users);
  const filteredItems = userProfileData.filter(
    (item: IModifiedUser) =>
      item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e: ChangeEvent<HTMLInputElement>) =>
          setFilterText(e.target.value)
        }
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  let [isOpen, setIsOpen] = useState(false);
  const userData = (data: any) => {
    setDisableModalData(data);
  };
  const modalStates = (data: any) => {
    setOpenDisableModal(data);
  };
  const actionType = (data: any) => {
    setAction(data);
  };

  function closeModal() {
    setIsOpen(false);
    setOpenDisableModal(false);
  }

  const userSearch = () => {
    // setUsername(usernames.current.value)
  };

    const getPending = () => {
    const config = {
      method: "get",
      url: `/api/v1/user/users-pending-approval`,
    };
    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data?.statusCode == 401){
          localStorage.clear();
          window.location.href = "/login";
        }
        console.log(response.data.data)
        setPendingUser(response.data.data);
      })
      .catch((error) => {
        var errorData = JSON.parse(error.request.response);
      });
  };


  useEffect(() => {
    dispatch(getAllUsers(usernames.current?.value) as unknown as AnyAction);
    getPending();
  }, [username]);

  const data = Array.isArray(users) ? users : [users];

  const addUser = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const config = {
      method: "post",
      url: `/api/v1/user/add-user`,
      contentType: "application/json-patch+json",
      data: {
        username: email,
        role: selected?.value,
      },
    };
    axiosInterceptorInstance(config)
      .then((response) => {
        if (response.data?.statusCode == 401){
          localStorage.clear();
          window.location.href = "/login";
        }
        setIsOpen(true);
        setModalData(response.data);
        setIsLoading(false);
        setSuccess(true);
        setUsername("");
      })
      .catch((error) => {
        var errorData = JSON.parse(error.request.response);
        setIsOpen(true);
        setModalData(errorData);
        setIsLoading(false);
        setSuccess(false);

        setUsername("");
      });
  };
  const handleChange = (selectedOption: any) => {
    setSelected(selectedOption);
  };
  const indicatorSeparatorStyle = {
    width: 0,
  };

  const IndicatorSeparator = ({ innerProps }: any) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown2 size="24" color="#292D32" />
      </components.DropdownIndicator>
    );
  };

  const options = [
    { value: "MAKER", label: "Maker" },
    { value: "CHECKER", label: "Checker" },
    { value: "ADMIN", label: "Admin" },
  ];
  return (
    <>
    <Toaster />
      <DisableUserModal
        openDisableModal={openDisableModal}
        closeModal={closeModal}
        data={disableModalData}
        action={action}
      />
      <MyModal
        isOpen={isOpen}
        closeModal={closeModal}
        data={modalData}
        success={success}
      />
      <div className="text-2xl text-zinc-800 font-bold">Profile Management</div>

      <section className=" bg-white rounded-2xl space-y-11 pb-10">
  

        <div className="flex flex-col m-12">
          <div className="text-2xl text-zinc-800 font-semibold mb-10">
            User Details
          </div>

          <form onSubmit={addUser} className="flex flex-row justify-between">
            <div className="w-[400px] ">
              <label className="block">
                <span className=" block text-base font-light text-slate-700">
                  Username / Email
                </span>
                <input
                  type="text"
                  className="placeholder:text-slate-400 block w-full bg-neutral-50 h-10 border border-neutral-50 rounded-md py-2 pr-16 pl-3 shadow-sm focus:outline-none focus:border-purple-600 focus:bg-white  sm:text-sm"
                  placeholder="John Doe"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
            </div>

            <div className="w-[400px] flex flex-col justify-start space-y-5">
              <div>
                <p className="text-zinc-800 font-light m-0">Role</p>

                <Select
                  onChange={handleChange}
                  autoFocus={true}
                  options={options}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      boxShadow: "none",
                      outlineColor: state.isFocused ? "white" : "white",
                      backgroundColor: "#FAFAFA",
                      "&:hover": {
                        border: "1px solid purple",
                      },
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? "#5C2684" : "#FAFBFB",
                      color: state.isFocused ? "#FBF9F9" : "#2D2235",
                      fontSize: 15,
                      fontWeight: 100,
                      height: 40,
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "2D2235",
                      fontSize: 15,
                      fontWeight: 100,
                    }),
                  }}
                  components={{ IndicatorSeparator, DropdownIndicator }}
                  className="basic-single"
                  classNamePrefix="Select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                />
              </div>
              {isLoading ? (
                <button
                  disabled
                  className="self-end rounded-md w-fit bg-purple-300 text-white text-[12px] font-semibold px-10 py-2"
                >
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="20"
                    color="#fff"
                    radius="7"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </button>
              ) : (
                <button
                  type="submit"
                  className="self-end rounded-md w-fit bg-purple-800 text-white text-[12px] font-semibold px-10 py-2"
                >
                  Add User
                </button>
              )}
            </div>
          </form>
        </div>


        <div className="m-12">
          <DataTable
            className="react-data-table-component"
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles as TableStyles}
          />
        </div>
        <div>
        <div className="text-2xl text-zinc-800 font-semibold mx-12 ">
            Pending User
          </div>
          <div className="mx-12">
          <DataTable
            className="react-data-table-component"
            columns={pendingColumn}
            data={pendingUser}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            // subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customStyles={customStyles as TableStyles}
          />
        </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
